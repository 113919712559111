import * as React from "react";
import { Layout } from "../../components/layout";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const NachzuchtPage = (props) => {
  const data = props.data.allNachzuchtYaml.edges;
  return (
    <>
      <Layout title="Nachzucht">
        <main className="container mb-3">
          <h1>Nachzucht</h1>
          {data.map(({ node }) => (
            <article className="row my-5">
              <GatsbyImage
                className="shadow col-md-6"
                quality={80}
                image={node.img.childImageSharp.gatsbyImageData}
                alt={node.titel}
                loading="lazy"
              />
              <div className="col-md-6">
                <h2 className="py-3">{node.titel}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: node.content,
                  }}
                ></div>
              </div>
            </article>
          ))}
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query NachzuchtPageQuery {
    allNachzuchtYaml {
      edges {
        node {
          content
          img {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          titel
        }
      }
    }
  }
`;

export default NachzuchtPage;
